//import { useEffect, useState } from "react";
import GlobalStyle from "./styles/global.js";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import Login from "./pages/signin/index.js";
import "react-toastify/dist/ReactToastify.min.css";
import "react-toastify/dist/ReactToastify.css";


//import axios from "axios";

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const Title = styled.h2``;

function UserManagementPage() {
 

  return (
    <>
      


        
        <Container>

      <Login />

      </Container>
      <ToastContainer autoClose={3000} position= "bottom-right" />
      <GlobalStyle />
    </>
  );
};

export default UserManagementPage;
