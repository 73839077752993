import axios from "axios";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const FormContainer = styled.form`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 5px #ccc;
  border-radius: 5px;
`;

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  width: 120px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 40px;
`;

const Label = styled.label``;

const Button = styled.button`
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #2c73d2;
  color: white;
  height: 42px;
`;

const Form = ({ getUsers, onEdit, setOnEdit }) => {
  const navigate = useNavigate();
  const signOut = () => {
    localStorage.removeItem('Token')
    navigate('/signinadmin');
  }
  const ref = useRef();

  useEffect(() => {
    if (onEdit) {
      const user = ref.current;

      user.nome_user.value = onEdit.nome_user;
      user.email_user.value = onEdit.email_user;
      user.senha_user.value = onEdit.senha_user;
      user.url_user.value = onEdit.url_user;
    }
  }, [onEdit]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = ref.current;

    if (
      !user.nome_user.value ||
      !user.email_user.value ||
      !user.senha_user.value ||
      !user.url_user.value
    ) {
      return toast.warn("Preencha todos os campos!");
    }

    if (onEdit) {
      await axios
        .put("http://elegemais.com.br:8088/" + onEdit.id_user, {
          nome_user: user.nome_user.value,
          email_user: user.email_user.value,
          senha_user: user.senha_user.value,
          url_user: user.url_user.value,
        })
        .then(({ data }) => toast.success(data))
        .catch(({ data }) => toast.error(data));
    } else {
      await axios
        .post("http://elegemais.com.br:8088/", {
          nome_user: user.nome_user.value,
          email_user: user.email_user.value,
          senha_user: user.senha_user.value,
          url_user: user.url_user.value,
        })
        .then(({ data }) => toast.success(data))
        .catch(({ data }) => toast.error(data));
    }

    user.nome_user.value = "";
    user.email_user.value = "";
    user.senha_user.value = "";
    user.url_user.value = "";

    setOnEdit(null);
    getUsers();
  };

  return (
    <FormContainer ref={ref} onSubmit={handleSubmit}>
      <InputArea>
        <Label>Nome</Label>
        <Input name="nome_user" />
      </InputArea>
      <InputArea>
        <Label>E-mail</Label>
        <Input name="email_user" type="email"  disabled={!!onEdit} />
      </InputArea>
      <InputArea>
        <Label>Senha</Label>
        <Input name="senha_user" type="password" />
      </InputArea>
      <InputArea>
        <Label>Dashboard</Label>
        <Input name="url_user" />
      </InputArea>

      <Button type="submit">Cadastrar</Button><Button type="submit" onClick={signOut}>Logout </Button>
    </FormContainer>
  );
};

export default Form;