import React from "react";

import "../../../../../App.css";



const Explorar = () => {

    return(
        <div>
          http://servicodados.ibge.gov.br/api/v3/noticias/
          </div>
    


    )
}

export default Explorar;
