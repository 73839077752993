import React from "react";
import Top from "./Top/top.js";
import Lista from "./Lista/lista.js";

import Explorar from "./Explorar/explorar.js";
import Faq from "./Faq/faq.js";
import "./body.css"



const Body = ({ currentPage }) => {

    return(
        <div className="mainContent">
            <Top />

            <div className="bottom flex">
                
            
            
            {currentPage === "dashboard" && <Lista />}
           
            {currentPage === "explorar" && <Explorar />}
            {currentPage === "faq" && <Faq />}
        

                
            </div>
        
        
        </div>
    


    )
}

export default Body;