import "../signin/index.css";
import '../../App.css';
import { FaUserShield } from "react-icons/fa";
import {AiOutlineSwapRight} from "react-icons/ai";
import { MdMarkEmailRead } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";
import axios from "axios";
import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom'
import { toast } from "react-toastify";
import logo from "../../assets/ElegeMais01.png";
import logoHorizontal from "../../assets/vincc1.png";
import NavbarCalc from "../landpage/Components/navbarCalc.js";
import { FaWhatsapp } from "react-icons/fa";



const Contato = () => {

    
    
    const navigate = useNavigate();
    const ref = useRef();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const user = ref.current;

        if (!user.idnome.value || !user.idemail.value || !user.idtel.value) {
            return toast.warn("Preencha todos os campos!");
        }
        
        
           const response = axios.post("http://elegemais.com.br:8088/enviar", {
                idnome: user.idnome.value,
                idemail: user.idemail.value,
                idtel: user.idtel.value,
            }).then(({ data }) => toast.success(data) === navigate('/comercial') )
            
            .catch(() => toast.error("Ja foi enviado um Formulario com este email"));

                        
        }
            

           

    return (
       

<div className="registerPage flex"> 
<NavbarCalc />
  <div className="container flex"> 

      <div className="imageDiv">
        <img src={logo} alt="ElegeMais"></img>

        
        <div className="footerDiv flex">
          <span className="text">Deseja mais informações?</span>
          <a href="https://wa.me/559981242197" target="_blank" rel="noopener noreferrer">
                <button className="btn"><FaWhatsapp /> WhatsApp</button>
            </a>
        </div>
      </div>

      <div className="formDiv flex"> 
        <div className="headerDiv">
        <a href="/landpage">
          <img src={logoHorizontal} alt="Vincc1" />
          </a>
          <h3></h3>
        </div> 

        <form ref={ref} onSubmit={handleSubmit} className="form grid">
          <span className="showMessage">Preencha o formulário abaixo para receber nossa proposta comercial:</span>

          <div className="inputDiv">
            <label htmlFor="idnome">Nome</label>
            <div className="input flex">
              <FaUserShield className="icon"/>
              <input type="text" id="idnome" placeholder="Nome completo" />
            </div>
          </div>

          <div className="inputDiv">
            <label htmlFor="idemail">Email</label>
            <div className="input flex">
              <MdMarkEmailRead  className="icon"/>
              <input type="email" id="idemail" placeholder="Email" />
            </div>
          </div>

          <div className="inputDiv">
            <label htmlFor="idtel">Telefone</label>
            <div className="input flex">
              <BsFillTelephoneFill className="icon"/>
              <input type="tel" id="idtel" placeholder="(xx) xxxxx-xxxx" />
            </div>
          </div>

          <button type="submit" className="btn flex">
            <span>Enviar</span>
            <AiOutlineSwapRight className="icon" />
          </button>
         

        </form>

      </div>

  </div>
</div>


    )
}

export default Contato;
