import React from "react";
import "./comercial.css";
import PDF from "../../../assets/PropostaComercial.pdf";
import Comercial01 from "../../../assets/PropostaComercial/1.png";
import Comercial02 from "../../../assets/PropostaComercial/2.png";
import Comercial03 from "../../../assets/PropostaComercial/3.png";
import Comercial04 from "../../../assets/PropostaComercial/4.png";
import Comercial05 from "../../../assets/PropostaComercial/5.png";
import Comercial06 from "../../../assets/PropostaComercial/6.png";
import Comercial07 from "../../../assets/PropostaComercial/7.png";
import Comercial08 from "../../../assets/PropostaComercial/8.png";
import Comercial09 from "../../../assets/PropostaComercial/9.png";
import Comercial10 from "../../../assets/PropostaComercial/10.png";
import Comercial11 from "../../../assets/PropostaComercial/11.png";
import Comercial12 from "../../../assets/PropostaComercial/12.png";
import Comercial13 from "../../../assets/PropostaComercial/13.png";
import Comercial14 from "../../../assets/PropostaComercial/14.png";
import NavbarCalc from "../Components/navbarCalc.js"


const Comercial = () => {

    

  return (

    
    <div className="registerPageCom flex"> 
    <NavbarCalc />
    

      <div className="containerCom "> 
      
      <img src={Comercial01} />
      <img src={Comercial02} />
      <img src={Comercial03} />
      <img src={Comercial04} />
      <img src={Comercial05} />
      <img src={Comercial06} />
      <img src={Comercial07} />
      <img src={Comercial08} />
      <img src={Comercial09} />
      <img src={Comercial10} />
      <img src={Comercial11} />
      <img src={Comercial12} />
      <img src={Comercial13} />
      <img src={Comercial14} />

      </div>

        
      

      

  </div>
    
    
  );
}

export default Comercial;
