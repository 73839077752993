import React, { useState, useEffect } from "react";

import "../../../../../App.css";
//import logo from "../../../../assets/Logo_ElegeMais1.png";



const Lista = () => {
    const [userUrl, setUserUrl] = useState("");

    useEffect(() => {
        
        const getUserUrlFromToken = () => {
            const token = localStorage.getItem("Token");
            if (token) {
                // Decodifica o token JWT para obter as informações do usuário
                const decodedToken = atob(token.split(".")[1]);
                const parsedToken = JSON.parse(decodedToken);
                setUserUrl(parsedToken.userUrl); 
            }
        };

        getUserUrlFromToken(); 

    }, []);

    return(
        
        <div className="powerBi">
            <h2>Dashboard</h2>
           <embed src={userUrl}  frameborder="0" allowFullScreen="true" />
        
        </div>
    


    )
}

export default Lista;