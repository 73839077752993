import React  from "react";
//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import RoutesApp from "./routes";
import './App.css';





const App = () => {
    
  return (
    <BrowserRouter>
    <div>
    
      <RoutesApp />
      
      
      <ToastContainer autoClose={3000} position= "bottom-right" />
      </div>
      </BrowserRouter>
  );
}

export default App;