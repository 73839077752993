import React, { useState, useEffect } from "react";




import "./top.css";
//import logo from "../../../../assets/Logo_ElegeMais1.png";



const Top = () => {
    const [userName, setUserName] = useState("");

    useEffect(() => {
        // Função para decodificar o token JWT e obter o nome do usuário
        const getUserNameFromToken = () => {
            const token = localStorage.getItem("Token");
            if (token) {
                // Decodifica o token JWT para obter as informações do usuário
                const decodedToken = atob(token.split(".")[1]);
                const parsedToken = JSON.parse(decodedToken);
                setUserName(parsedToken.userName); // Supondo que o nome do usuário esteja armazenado como 'userName' no token
            }
        };

        getUserNameFromToken(); // Chama a função ao montar o componente

    }, []);

    return(
        <div className="topSection">
           <div className="headerSection flex">
                <div className="title">
                    <h1>Bem-vindoª <p>{userName}</p></h1>
                    
                </div>

               

                {/*<div className="adminDiv flex">
                    <FaWhatsapp className="icon" />
                    <FaInstagram className="icon" />
                </div>*/}
        
            </div>
        </div>
    


    )
}

export default Top;