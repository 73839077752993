import React, { useState, useEffect } from "react";
import "./calculadora.css";
import axios from 'axios';
import TRE from "../../../assets/vincc1.png";
import brasao from "../../../assets/brasao.png";
import { FaBackspace } from "react-icons/fa";
import NavbarCalc from "../Components/navbarCalc.js"



const Calculadora = () => {

    const [estado, setEstado] = useState([]);
    const [cidade, setCidades] = useState([]);
    const [estadoSelecionado, setEstadoSelecionado] = useState('');
    const [cidadeSelecionada, setCidadeSelecionada] = useState('');
    const [votosValidos, setVotosValidos] = useState('');
    const [totalVereadores, setTotalVereadores] = useState('');
    const [quocienteEleitoral, setQuocienteEleitoral] = useState('');
    const [votosCandidato, setVotosCandidato] = useState('');
    const [quocientePartidario, setQuocientePartidario] = useState('');
    
    

    useEffect(() => {
        axios.get("http://elegemais.com.br:8088/estado")
      .then((response) => {
        setEstado(response.data); 
      })
      .catch((error) => {
        
        console.error('Erro ao carregar estados:', error);
      });

      
  }, []);
            
  const handleEstadoChange = (event) => {
    const EstadoSelecionadoId = event.target.value;
    setEstadoSelecionado(EstadoSelecionadoId);
  

          axios.get(`http://elegemais.com.br:8088/cidade/${EstadoSelecionadoId}`)
          .then((response) => {
            setCidades(response.data);
        })
        .catch((error) => {
            
            console.error('Erro ao carregar cidades:', error);
        });
    };

    const handleCidadeChange = (event) => {
        const cidadeSelecionadaId = event.target.value;
        setCidadeSelecionada(cidadeSelecionadaId);

        axios.get(`http://elegemais.com.br:8088/cidade/${cidadeSelecionadaId}/votos`)
        .then((response) => {
            setVotosValidos(response.data.votos); 
        })
        .catch((error) => {
            
            console.error('Erro ao carregar quantidade de votos válidos:', error);
        });
    };

    const handleTotalVereadoresChange = (event) => {
        const totalVereadores = event.target.value;
        setTotalVereadores(totalVereadores);
    };

    const calcularQuociente = (event) => {
      event.preventDefault();
        if (votosValidos && totalVereadores) {
            const quociente = votosValidos / totalVereadores;
            const parteInteira = Math.floor(quociente);
            const parteDecimal = quociente - parteInteira;
            let resultadoQuociente;
    
            if (parteDecimal === 0 || parteDecimal < 0.5) {
                resultadoQuociente = parteInteira;
            } else {
                resultadoQuociente = Math.ceil(quociente);
            }
    
            setQuocienteEleitoral(resultadoQuociente);

            
        }
    };

    

    const handleVotosCandidatoChange = (event) => {
        const votosCandidatoValue = event.target.value;
        setVotosCandidato(votosCandidatoValue);
    };

    const calcularQuocientePartidario = () => {
      
        if (votosCandidato && quocienteEleitoral) {
            const quocientePartidarioValue = votosCandidato / quocienteEleitoral;
            setQuocientePartidario(Math.floor(quocientePartidarioValue));
        }
       
    };

    

    const openModal = () => {
      const modal = document.querySelector('.modal-container');
      if (modal) {
          modal.classList.add('active');
      }
  };

  const closeModal = () => {
      const modal = document.querySelector('.modal-container');
      if (modal) {
          modal.classList.remove('active');
      }
  };


const handleClickCalc = (e) => {
  e.preventDefault();
  calcularQuocientePartidario();
  openModal();
  
  
};

const handleClickClose = (e, redirect = false) => {
  e.preventDefault();
  closeModal();
  if (redirect) {
    window.open("https://wa.me/559981242197", "_blank");
}
  
};

const Destacar = () => {
  const destaque = document.querySelector('.modal-container');
  if (destaque) {
      destaque.classList.add('active');
  }
};

  return (

    <div className=""> 
      <NavbarCalc />
    <div className="registerPageCalc flex"> 
    

      <div className="containerCalc "> 
     
      
          <div className="formDivCalc flex"> 
            <div className="headerDivCalc">
            <div><img src={TRE} alt="Vincc1" /></div>
            
            <a href="/landpage">
              <img src={TRE} alt="Vincc1" />
            </a>
              <h3>Calculadora Eleitoral</h3>
            </div> 

        <form className="formCalc grid">
          <span className="showMessage">Quociente Eleitoral</span>

          <div className="inputDivCalc">
            
            <div className="inputDivCalc">
            <label htmlFor="idemail">Estado:</label>
            <div className="inputCalc flex">
                <select  value={estadoSelecionado} onChange={handleEstadoChange}>
                        <option  value="">Selecione um estado</option>
                            {estado && estado.length > 0 && estado.map((estado) => (
                        <option  key={estado.id} value={estado.id}>{estado.nome}</option>
        ))}
                </select>
                </div>
                </div>
                <br />
                <div className="inputDivCalc">
                <label htmlFor="idemail">Cidade:</label>
                <div className="inputCalc flex">
                    <select  value={cidadeSelecionada} onChange={handleCidadeChange}>
                      <option  value="">Selecione uma cidade</option>
                          {cidade && cidade.length > 0 && cidade.map(cidade => (
                        <option  key={cidade.id} value={cidade.id}>{cidade.nome}</option>
                          ))}
                    </select>
        </div>
            </div>
            
          </div>

          <div className="inputDivCalc">
            <label htmlFor="idemail">Total de votos válidos na última eleição:</label>
                <div className="inputCalc flexCalc">
                    <input  value={votosValidos} />   
                </div>
                </div>
                <div className="inputDivCalc">
            <label htmlFor="idemail">Total de Veradores na Cidade:</label>
                <div className="inputCalc flexCalc">
                    <input type="number" value={totalVereadores} onChange={handleTotalVereadoresChange} />   
                </div>
                </div>

                <button onClick={calcularQuociente} className="btnCalc flex">
            <span>Calcular Quociente Eleitoral</span>
            
          </button>

                <div className="inputDivCalc">
                <label htmlFor="idemail">Quociente Eleitoral:</label>
                <div className=" inputCalc flexCalc inputBorder">
                <input className="inputDestaque" value={quocienteEleitoral} /> 
                 
                </div>
            
          </div>

          

          
          

        </form>
        

      </div>

        
      <div class="modal-container">
    <div class="modal">
      <h2>Olá! 😃
        
      </h2>
      <br />
      <hr />
      <span>
        Gostou da nossa calculadora e deseja saber mais sobre nosso serviço? 
      </span>
      <hr />
      <div class="btns">
        <button type="button" class="btnOK" onClick={(e) => handleClickClose(e, true)}>Sim, gostaria!</button> 
        <button class="btnClose" onClick={handleClickClose}>Não, em um outro momento.</button>
      </div>
    </div>
  </div>

      <div className="formDivCalc flex"> 
      
        <div className="headerDivCalc">
          
        </div> 
    
        <form className="formCalc grid">
          
          <span className="showMessage">  Quociente Partidário  </span>
          

          <div className="inputDivCalc">
         
            <label htmlFor="idnome">Projeção de Votos do partido para 2024:</label>
            <div className="inputCalc flexCalc">
              
              <input type="number" value={votosCandidato} onChange={handleVotosCandidatoChange} />
            </div>
          </div>

          <button onClick={handleClickCalc} className="btnCalc flex">
            <span>Calcular Quociente Partidario</span>
            
          </button>


          <div className="inputDivCalc">
            <label htmlFor="idemail">Numero de Cadeiras que o partido terá direito:</label>
            <div className="inputCalc flexCalc inputBorder">
              
              <input className="inputDestaque" value={quocientePartidario} />  
            </div>
          </div>
          

          

         
          

        </form>

      </div>

  </div>
</div>   



</div>
  );
}

export default Calculadora;
