import "./landpage.css";
import Site from "./Components/site";


import Footer from "./Components/footer";


function Landsite() {
  return (
    <div className="Land">
      
      <Site />
      
      
      
      
      <Footer />
    </div>
  );
}

export default Landsite;