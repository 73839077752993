import "./register.css";
import '../../App.css';
import InputMask from 'react-input-mask';
import { FaUserShield } from "react-icons/fa";
import { AiOutlineSwapRight } from "react-icons/ai";
import { MdMarkEmailRead } from "react-icons/md";
import { FaCity } from "react-icons/fa";
import { FaArrowRightToCity } from "react-icons/fa6";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaCalendarAlt } from "react-icons/fa";
import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import logo from "../../assets/ElegeMais01.png";
import logoHorizontal from "../../assets/vincc1.png";
import NavbarCalc from "../landpage/Components/navbarCalc.js";
import { FaWhatsapp } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";

const Register = () => {
  const [estado, setEstado] = useState([]);
  const [cidade, setCidades] = useState([]);
  const [estadoSelecionado, setEstadoSelecionado] = useState('');
  const [estadoSelecionadoNome, setEstadoSelecionadoNome] = useState('');
  const [cidadeSelecionada, setCidadeSelecionada] = useState('');
  const [cidadeSelecionadaNome, setCidadeSelecionadaNome] = useState('');

  const navigate = useNavigate();
  const ref = useRef();

  const isValidCPF = (cpf) => {
    cpf = cpf.replace(/\D/g, '');

    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
      return false;
    }

    let soma = 0;
    let resto;

    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpf.substring(i-1, i)) * (11 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.substring(9, 10))) {
      return false;
    }

    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpf.substring(i-1, i)) * (12 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.substring(10, 11))) {
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = ref.current;

    if (!user.nome_user.value || !user.email_user.value || !user.senha_user.value || !user.senha_user_conf.value || !user.cpf_user.value || !user.data_nascimento_user.value || !user.urna_user.value || !user.candidatura_user.value || !user.partido_user.value || !user.tel_user.value || !estadoSelecionadoNome || !cidadeSelecionadaNome) {
      return toast.warn("Preencha todos os campos!");
    }

    if (!isValidCPF(user.cpf_user.value)) {
      return toast.warn("CPF inválido!");
    }

    if (user.senha_user.value !== user.senha_user_conf.value) {
      return toast.warn("As senhas não coincidem!");
    }

    try {
      const response = await axios.post("http://elegemais.com.br:8088/addregister", {
        nome_user: user.nome_user.value,
        email_user: user.email_user.value,
        senha_user: user.senha_user.value,
        cpf_user: user.cpf_user.value,
        data_nascimento_user: user.data_nascimento_user.value,
        urna_user: user.urna_user.value,
        candidatura_user: user.candidatura_user.value,
        partido_user: user.partido_user.value,
        tel_user: user.tel_user.value,
        estado_user: estadoSelecionadoNome,
        cidade_user: cidadeSelecionadaNome,
      });

      toast.success(response.data);
      navigate('/signin');
    } catch (error) {
      toast.error("Erro ao cadastrar");
    }
  };

  useEffect(() => {
    axios.get("http://elegemais.com.br:8088/estado")
      .then((response) => {
        setEstado(response.data);
      })
      .catch((error) => {
        console.error('Erro ao carregar estados:', error);
      });
  }, []);

  const handleEstadoChange = (event) => {
    const estadoId = event.target.value;
    const estadoNome = event.target.options[event.target.selectedIndex].text;
    setEstadoSelecionado(estadoId);
    setEstadoSelecionadoNome(estadoNome);

    axios.get(`http://elegemais.com.br:8088/cidade/${estadoId}`)
      .then((response) => {
        setCidades(response.data);
      })
      .catch((error) => {
        console.error('Erro ao carregar cidades:', error);
      });
  };

  const handleCidadeChange = (event) => {
    const cidadeId = event.target.value;
    const cidadeNome = event.target.options[event.target.selectedIndex].text;
    setCidadeSelecionada(cidadeId);
    setCidadeSelecionadaNome(cidadeNome);

    axios.get(`http://elegemais.com.br:8088/cidade/${cidadeId}/votos`)
      .then((response) => {
        // Handle the response if necessary
      })
      .catch((error) => {
        console.error('Erro ao carregar quantidade de votos válidos:', error);
      });
  };

  return (
    <div className="registerPage flex">
      <NavbarCalc />
      <div className="container flex">
        <div className="imageDiv">
          <img src={logo} alt="ElegeMais"></img>
          <div className="footerDiv flex">
            <span className="text">Deseja mais informações?</span>
            <a href="https://wa.me/559981242197" target="_blank" rel="noopener noreferrer">
              <button className="btn"><FaWhatsapp /> WhatsApp</button>
            </a>
          </div>
        </div>

        <div className="formDiv flex">
          <div className="headerDiv">
            <a href="/landpage">
              <img src={logoHorizontal} alt="Vincc1" />
            </a>
            <h3>Sistemas Vincc1</h3>
          </div>

          <form ref={ref} onSubmit={handleSubmit} className="form grid">
            <div className="inputDiv">
              <label htmlFor="idnome">Nome*</label>
              <div className="input flex">
                <FaUserShield className="icon" />
                <input type="text" name="nome_user" placeholder="Nome completo" />
              </div>
            </div>

            <div className="inputDiv">
              <label htmlFor="idemail">Email*</label>
              <div className="input flex">
                <MdMarkEmailRead className="icon" />
                <input type="email" name="email_user" placeholder="Email" />
              </div>
            </div>

            <div className="inputDiv">
              <label htmlFor="idcpf">CPF*</label>
              <div className="input flex">
                <FaUserShield className="icon" />
                <InputMask
            mask="999.999.999-99"
            maskPlaceholder=""
            type="text"
            name="cpf_user"
            placeholder="CPF"
            
          />

              </div>
            </div>

            <div className="inputDiv">
              <label htmlFor="iddatanasc">Data de Nascimento*</label>
              <div className="input flex">
                <FaCalendarAlt className="icon" />
                <input type="date" name="data_nascimento_user" placeholder="Data" />
              </div>
            </div>

            <div className="inputDiv">
              <label htmlFor="idurna">Nome de Urna*</label>
              <div className="input flex">
                <FaUserShield className="icon" />
                <input type="text" name="urna_user" placeholder="Nome de Urna" />
              </div>
            </div>

            <div className="inputDiv">
              <label htmlFor="idcandidatura">Número de candidatura*</label>
              <div className="input flex">
                <FaUserShield className="icon" />
                <input type="number" name="candidatura_user" placeholder="Candidatura" />
              </div>
            </div>

            <div className="inputDiv">
              <label htmlFor="idpartido">Partido*</label>
              <div className="input flex">
                <FaUserShield className="icon" />
                <input type="text" name="partido_user" placeholder="Partido" />
              </div>
            </div>

            <div className="inputDiv">
              <label htmlFor="idtelefone">Telefone*</label>
              <div className="input flex">
                <BsFillTelephoneFill className="icon" />
                <input type="tel" name="tel_user" placeholder="(xx) x xxxx-xxxx" />
              </div>
            </div>

            <div className="inputDiv">
              <label htmlFor="idestado">Estado*</label>
              <div className="input flex">
                <FaCity className="icon" />
                <select name="estado_user" value={estadoSelecionado} onChange={handleEstadoChange}>
                  <option value="">Selecione um estado</option>
                  {estado.map((estado) => (
                    <option key={estado.id} value={estado.id}>{estado.nome}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="inputDiv">
              <label htmlFor="idcidade">Cidade*</label>
              <div className="input flex">
                <FaArrowRightToCity className="icon" />
                <select name="cidade_user" value={cidadeSelecionada} onChange={handleCidadeChange}>
                  <option value="">Selecione uma cidade</option>
                  {cidade.map((cidade) => (
                    <option key={cidade.id} value={cidade.id}>{cidade.nome}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="inputDiv">
              <label htmlFor="idsenha">Senha*</label>
              <div className="input flex">
                <RiLockPasswordFill className="icon" />
                <input type="password" name="senha_user" placeholder="Digite sua senha" />
              </div>
            </div>

            <div className="inputDiv">
              <label htmlFor="idsenha_conf">Confirme Senha*</label>
              <div className="input flex">
                <RiLockPasswordFill className="icon" />
                <input type="password" name="senha_user_conf" placeholder="Confirme sua senha" />
              </div>
            </div>

            <button type="submit" className="btn btnGrid flex">
              <span>Enviar</span>
              <AiOutlineSwapRight className="icon" />
            </button>
            <div className="required">*Campos obrigatorios</div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
