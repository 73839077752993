import "./landpage.css";
import Dash from "./Components/dash";


import Footer from "./Components/footer";


function Landdashboard() {
  return (
    <div className="Land">
      
      <Dash />
      
      
      
      
      <Footer />
    </div>
  );
}

export default Landdashboard;