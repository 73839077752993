import React from "react";
import "./index.css";

import { IoBarChartSharp } from "react-icons/io5";
import { BsQuestionCircle } from "react-icons/bs";

import { IoLogOutSharp } from "react-icons/io5";

import { FaWhatsapp } from "react-icons/fa";
import { useNavigate  } from "react-router-dom";





//import "../../../../App.css";

import logoIcon from "../../../../assets/ElegeMais.png";



const Sidebar = ({ onMenuItemClick }) => {
    
    const navigate = useNavigate();
    
    const signOut = () => {
        localStorage.removeItem('Token')
        navigate('/landpage');
      }

      
      

    return (
        <div className="sideBar grid">
            <div className="logoDiv flex">
                <img src={logoIcon} alt="Elege Mais" />
                
            </div>
            <div className="menuDiv">
                <h2 className="divTitle">
                     MENU
                </h2>
                <ul className="menuLists grid">
                    <li className="listsItem">
                        <a href="#" onClick={() => onMenuItemClick("dashboard")} className="menuLink flex">
                            <IoBarChartSharp className="icon" />
                            <span className="smallText">
                                Dashboard
                            </span>

                        </a>
                    </li>
                    
                  
                    <li className="listsItem">
                        <a href="" className="menuLink flex">
                            <IoLogOutSharp className="icon" />
                            <span className="smallText" onClick={signOut}>
                               Sair
                            </span>

                        </a>
                    </li>
                    
                    
                </ul>
            </div>

            <div className="configDiv">
                <h3 className="divTitle"></h3>
                <ul className="menuLists grid">
                    
                    
                    
                </ul>
            </div>

            <div className="sideBarCard">
                <BsQuestionCircle className="icon"/>
                <div className="cardContent">
                    <div className="circle1"></div>
                    <div className="circle2"></div>
                    <h3>Precisa de Ajuda?</h3>
                    <p>Entre em contato conosco.</p>
                    
                    <a href="https://wa.me/559981242197" target="_blank"><button  className="btn"><FaWhatsapp /> Central de Ajuda</button></a>
                    

                </div>

            </div>

    </div>
    


    )
}

export default Sidebar