import "../signin/index.css";
import '../../App.css';
import { MdMarkEmailRead } from "react-icons/md";
import { BsFillShieldLockFill } from "react-icons/bs";
import {AiOutlineSwapRight} from "react-icons/ai";
import axios from "axios";
import React, { useRef } from "react";
import { useNavigate, Link } from 'react-router-dom'
import { toast } from "react-toastify";
import { setToken } from "../../contexts/auth";
import logo from "../../assets/ElegeMais01.png";
import logoHorizontal from "../../assets/vincc1.png";
<link rel="stylesheet" href="../../frontend/src/App.css"></link>






const Login = () => {

    
    
    const navigate = useNavigate();
    const ref = useRef();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const user = ref.current;

        if (!user.email_user_admin.value || !user.senha_user_admin.value) {
            return toast.warn("Preencha todos os campos!");
        }
        
        
            axios.post("http://elegemais.com.br:8088/loginadmin", {
                email_user_admin: user.email_user_admin.value,
                senha_user_admin: user.senha_user_admin.value,
            }).then(res => {
                console.log(res);
                if(res.data.Status === 'Logado'){
                    
                    setToken(res.data.Token)
                    navigate('/signup');
                    toast.success("Usuário logado com sucesso.");
                    
                }else {
                    toast.error("Erro ao processar a resposta do servidor");
                }
            })
            .catch(err => console.log(err));
        }
            

           

    return (
        <div className="loginPage flex"> 
  <div className="container flex"> 

      <div className="imageDiv">
        <img src={logo} alt="ElegeMais"></img>

        
        <div className="footerDiv flex">
          <span className="text">Deseja logar como usuario?</span>
          <Link to={'/signin'}>
          <button className="btn">Login Usuário</button>
          </Link>
        </div>
      </div>

      <div className="formDiv flex"> 
        <div className="headerDiv">
          <img src={logoHorizontal} alt="Vincc1" />
          <h3>Sistemas Vincc1</h3>
        </div> 

        <form ref={ref} onSubmit={handleSubmit} className="form grid">
          <span className="showMessage">Login Administrador</span>
          <div className="inputDiv">
            <label htmlFor="email_user_admin">Email</label>
            <div className="input flex">
              <MdMarkEmailRead className="icon"/>
              <input type="email" id="email_user_admin" placeholder="Digite seu email" />
            </div>
          </div>

          <div className="inputDiv">
            <label htmlFor="senha_user_admin">Senha</label>
            <div className="input flex">
              <BsFillShieldLockFill className="icon"/>
              <input type="password" id="senha_user_admin" placeholder="Digite sua senha" />
            </div>
          </div>
          <button type="submit" className="btn flex">
            <span>Login</span>
            <AiOutlineSwapRight className="icon" />
          </button>
          

        </form>

      </div>

  </div>
</div>
    );
};

export default Login;
