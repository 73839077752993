/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Logo from "../../../assets/Logo_Elege_Mais_Deitada.png";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FaPhone } from "react-icons/fa";
import { FaCalculator } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";
import { FaInfo } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import "./navbar.css";


const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  const menuOptions = [
    {
      text: "Home",
      icon: <FaHome />,
      link: "/landpage",
      
      
    },
    {
      text: "Sobre",
      icon: <FaInfo />,
      link: "/sobre",
    },
    {
      text: "Calculadora",
      icon: <FaCalculator />,
      link: "/calculadora",
    },
    {
      text: "Contato",
      icon: <FaPhone />,
      link: "/contato",
    },
    
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) { 
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className="navbar-fixed">
      <div className="nav-logo-container">
        <img src={Logo} alt="" />
      </div>



      
      <div  className="navbar-links-container header">
      <a href="/landpage" className="link">Home</a>
      <a href="/landpage" className="link">Sobre</a>
        
        <a href="/calculadora" className="link">Calculadora de Quociente</a>
        
        <a href="/contato" className="link">Contato</a>
        {/*<a href="">  <BsCart2 className="navbar-cart-icon" />    </a>*/}
        <a href="/signin">
        <button className="primary-button ">Login</button>
        </a>
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton component={RouterLink} to={item.link}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
    </nav>
  );
};

export default Navbar;