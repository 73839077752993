import React, { useState }  from "react";
import Sidebar from "../dashboard/components/sideBar/index.js";
import Body from "./components/bodySection/body.js";
import "../../App.css"

const Dashboard = () => {
  const [currentPage, setCurrentPage] = useState("dashboard");

    const handleMenuItemClick = (page) => {
        setCurrentPage(page);
    };




  
  

  return (

    
    <div className="dashboard">
      <div className="dashboardContainer">
      
        <Sidebar onMenuItemClick={handleMenuItemClick} />
        <Body currentPage={currentPage} />
        
      </div>
      


    </div>
    
  )
}


export default Dashboard