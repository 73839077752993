import React from "react";
import BannerBackground from "../../../assets/home-banner-background.png";
import BannerImage from "../../../assets/4.png";
import Navbar from "./navbar.js";
import { FiArrowRight } from "react-icons/fi";
import { Element } from "react-scroll";


const Home = () => {
  return (
    
    <div className="home-container">
      <Element name="home">
          <section id="home"></section>
        </Element>
      <Navbar />
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        
        <div className="home-text-section">
          <h1 className="primary-heading">
          Elege+ 2024: Seu Sucesso nas Urnas Começa Aqui!
          </h1>
          <p className="primary-text">
          Bem-vindo ao Elege+, o seu parceiro essencial para as Eleições 2024! Oferecemos uma gama completa de serviços e produtos inovadores, especialmente desenvolvidos para candidatos a vereadores e prefeitos. Nossa missão é potencializar sua campanha com estratégias eficazes, tecnologia de ponta e suporte personalizado. Junte-se a nós e transforme sua visão em vitória!
          </p>
         <a href="/contato"> <button  className="secondary-button">
          Saiba mais <FiArrowRight />{" "}
          </button></a>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;