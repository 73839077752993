import React from 'react';
import { Navigate, useLocation } from "react-router-dom";

//Função que constroe o Provider e também permite Consumir os Dados Globais

export const setToken = (token) =>{
    localStorage.setItem('Token', token)
}
export const buscarToken = (token) =>{
    return localStorage.getItem('Token')
}
export function ExigirToken({children}) {

    let auth = buscarToken()
    let localizacao = useLocation()

    if (!auth) {
        return <Navigate to="/signin" state={{ from: localizacao}} />
    }

    return children;
}