import { Route, Routes } from "react-router-dom";
//import Navbar from "../components/navbar";
import Signinadmin from "../pages/signinadmin";
import Dashboard from "../pages/dashboard";
import Signin from "../pages/signin";
import Signup from "../pages/signup";
import Register from "../pages/register";
import UserManagementPage from "../UserManegamentPage"; 
import { ExigirToken } from "../contexts/auth";


import Lista from "../pages/dashboard/components/bodySection/Lista/lista.js";
import Calculadora from "../pages/landpage/Calculadora/calculadora.js";
import Home from "../pages/landpage/Components/home.js";
import About from "../pages/landpage/Components/about.js";
import Clientes from "../pages/landpage/Components/clientes.js";
import Contact from "../pages/landpage/Components/contact.js";
import Footer from "../pages/landpage/Components/footer.js";
import Work from "../pages/landpage/Components/work.js";
import Navbar from "../pages/landpage/Components/navbar.js";
import Landpage from "../pages/landpage/landpage.js";
import Comercial from "../pages/landpage/Comercial/comercial.js";
import Contato from "../pages/contato/index.js";
import Landdashboard from "../pages/landpage/landdashboard.js";
import Landsite from "../pages/landpage/landsite.js";


const RoutesApp = () => {
    
      return (
        <div className="App">
        
        
            <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/clientes" element={<Clientes />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/footer" element={<Footer />} />
                <Route path="/work" element={<Work />} />
                <Route path="/navbar" element={<Navbar />} />
                <Route path="/landdashboard" element={<Landdashboard />} />
                <Route path="/landsite" element={<Landsite />} />


                <Route path="/UserManagementPage" element={<UserManagementPage />} />
                
                <Route path="/dashboard" element={<ExigirToken><Dashboard /></ExigirToken>} />
                <Route path="/signup" element={<ExigirToken><Signup /></ExigirToken>} />
                <Route path="/lista" element={<Lista />} />
                <Route path="/calculadora" element={<Calculadora />} />
                <Route path="/comercial" element={<Comercial />} />
                <Route path="/signin" element={<Signin />} />
                <Route path="/register" element={<Register />} />
                <Route path="/contato" element={<Contato />} />
                <Route path="/signinadmin" element={<Signinadmin />} />
                <Route path="*" element={<Landpage />} />
            
                
                
            </Routes>
        
        </div>
    );
};

export default RoutesApp;
