import "./landpage.css";
import Home from "./Components/home";
import Work from "./Components/work";

import Footer from "./Components/footer";


function Landpage() {
  return (
    <div className="Land">
      
      <Home />
      
      <Work />
      
      
      <Footer />
    </div>
  );
}

export default Landpage;