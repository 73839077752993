import React from "react";

import "../../../../../App.css";
//import logo from "../../../../assets/Logo_ElegeMais1.png";



const Faq = () => {

    return(
        <div className="">
           FAQ
        
        
        </div>
    


    )
}

export default Faq;