import React from "react";
import Workshop from "../../../assets/1.png";
import Imersao from "../../../assets/2.png";
import Mentoria from "../../../assets/3.png";
import Consultoria from "../../../assets/4.png";
import dashboards from "../../../assets/5.png";
import site from "../../../assets/6.png";
import AboutBackground from "../../../assets/about-background.png";
import { Element } from "react-scroll";
import { Link } from "react-router-dom";

const Work = () => {
  const workInfoData = [
    {
      image: Workshop,
      title: "Workshop Do Zero ao Voto!",
      text: "Workshop poderoso que ensina a construir uma pré-campanha eleitoral impactante e vencedora",
      link: "https://pay.hotmart.com/W93652040G?checkoutMode=10",
    },
    {
      image: Imersao,
      title: "Imersão (Treinamento)",
      text: "Uma experiência imersiva que treina pré-candidatos na criação e implementação de estratégias para campanhas eleitorais",
      link: "https://pay.hotmart.com/M93561071Q?checkoutMode=10",
    },
    {
      image: Mentoria,
      title: "Mentoria Elege+",
      text: "Acompanhamento exclusivo direcionado ao pré-candidato, oferecendo orientação estratégica e apoio personalizado durante sua pré-campanha",
      link: "https://go.hotmart.com/M93801150L?dp=1",
    },
    {
      image: Consultoria,
      title: "Consultoria Especializada",
      text: "Suporte feito especialmente pra você, candidato a vereador, com orientação estratégica e assistência individualizada.",
      link: "https://go.hotmart.com/D93798612M?dp=1",
    },
    {
      image: dashboards,
      title: "Dashboards Simples",
      text: "Acompanhamento exclusivo direcionado ao pré-candidato, oferecendo orientação estratégica e apoio personalizado durante sua pré-campanha",
      link: "https://pay.hotmart.com/O93432134F?off=e6l1xach&checkoutMode=10",
    },
    {
      image: dashboards,
      title: "Dashboards Perfil do Eleitor",
      text: "Acompanhamento exclusivo direcionado ao pré-candidato, oferecendo orientação estratégica e apoio personalizado durante sua pré-campanha",
      link: "https://pay.hotmart.com/F93469029N?off=pwzd36yq&checkoutMode=10",
    },
    {
      image: site,
      title: "Site Institucional",
      text: "Acompanhamento exclusivo direcionado ao pré-candidato, oferecendo orientação estratégica e apoio personalizado durante sua pré-campanha",
      link: "https://pay.hotmart.com/U93651865Q?off=0nq0uaea&checkoutMode=10",
    },
  ];

  return (
    <div className="work-section-wrapper">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="work-section-top">
        <Element name="sobre">
          <section id="sobre"></section>
        </Element>
        <p className="primary-subheading">Sobre</p>
        <h1 className="primary-heading">O Projeto</h1>
        <p className="primary-text">
          O Elege+ é um projeto inovador criado para transformar a maneira como candidatos a vereadores e prefeitos se preparam para as eleições. Nossa missão é fornecer uma plataforma completa e acessível que abrange todos os aspectos de uma campanha eleitoral bem-sucedida, desde a concepção inicial até a vitória nas urnas
        </p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            {data.link ? (
              data.link.startsWith("http") ? (
                <a href={data.link} target="_blank" rel="noopener noreferrer" className="info-boxes-img-container">
                  <img src={data.image} alt={data.title} />
                  <h2>{data.title}</h2>
                  <p>{data.text}</p>
                </a>
              ) : (
                <Link to={data.link} className="info-boxes-img-container">
                  <img src={data.image} alt={data.title} />
                  <h2>{data.title}</h2>
                  <p>{data.text}</p>
                </Link>
              )
            ) : (
              <>
                <div className="info-boxes-img-container">
                  <img src={data.image} alt={data.title} />
                </div>
                <h2>{data.title}</h2>
                <p>{data.text}</p>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
