import { createGlobalStyle } from "styled-components";

const Global = createGlobalStyle`

   
  body {
    
    
    background-color: #0e4378;
  }
`;

export default Global;