import React from "react";
import BannerBackground from "../../../assets/home-banner-background.png";
import BannerImage from "../../../assets/6.png";
import NavbarCalc from "./navbarCalc.js";
import { FiArrowRight } from "react-icons/fi";
import { Element } from "react-scroll";


const Site = () => {
  return (
    
    <div className="home-container">
      <Element name="home">
          <section id="home"></section>
        </Element>
      <NavbarCalc />
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        
        <div className="home-text-section">
          <h1 className="primary-heading">
          Site Institucional
          </h1>
          <p className="primary-text">
          Acompanhamento exclusivo direcionado ao pré-candidato, oferecendo orientação estratégica e apoio personalizado durante sua pré-campanha.
          </p>
         <a href="https://hotmart.com/pt-br" target="_blank"> <button  className="secondary-button">
          Adquira agora! <FiArrowRight />{" "}
          </button></a>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Site;