import React from "react";
import Logo from "../../../assets/vincc1.png";
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { TbWorldWww } from "react-icons/tb";

import { HiOutlineMail } from "react-icons/hi";


const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={Logo} alt="Vincc1" />
        </div>
        <div className="footer-icons">
        <a href="https://vincc1.com.br"><TbWorldWww /></a>
         <a href="https://www.instagram.com/vincc1tic"><FaInstagram /></a>
         <a href="https://wa.me/559981242197" target="_blank"><FaWhatsapp /></a>
         <div className="footer-logo-container">
         <span className="address">TV. Frederico Leda, 1725, Cohab - Bacabal/MA</span>
         <span></span>
         </div>
        </div>
        
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          
          <span>Conheça</span>
          <span></span>
          
          <a href="https://escolinedigital.com.br/"><span>Escoline Digital</span></a>
          <span></span>
          <a href="https://elegemais.com.br/"><span>Elege Mais</span></a>
          
        </div>
        <div className="footer-section-columns">
          <span>Fale Conosco</span>
          <span></span>
          <span><FaWhatsapp className="icon" /> 99 98124-2197</span>
          <span><HiOutlineMail className="icon" /> contato@elegemais.com.br</span>
          <span><TbWorldWww className="icon"/> elegemais.com.br</span>
          
        </div>
        <div className="footer-section-columns">
        <span></span>
        <span></span>
        <span></span>
          <span>Copyright 2024 © Elege Mais</span>
          <span>Todos os direitos reservados</span>
          <span>Vincc1 Tecnologia e Inovação LTDA</span>
        </div>
        
      </div>
    
    </div>
    
  );
};

export default Footer;